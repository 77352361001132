// This is an empty scss file for your custom styles - Please add your super duper awesome mega design styles here
body { 
    background: #ffffff !important; 
    font-family: 'kumbh_sansregular', Helvetica; 
    -webkit-transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
	-ms-transition: all 0.2s linear;
	transition: all 0.2s linear;
}


@font-face {
    font-family: 'kumbh_sansbold';
    src: url('/wordpress/wp-content/themes/understrap/fonts/kumbhsans-bold-webfont.woff2') format('woff2'),
         url('/wordpress/wp-content/themes/understrap/fonts/kumbhsans-bold-webfont.woff') format('woff');
}


@font-face {
    font-family: 'kumbh_sanslight';
    src: url('/wordpress/wp-content/themes/understrap/fonts/kumbhsans-light-webfont.woff2') format('woff2'),
         url('/wordpress/wp-content/themes/understrap/fonts/kumbhsans-light-webfont.woff') format('woff');
}


@font-face {
    font-family: 'kumbh_sansregular';
    src: url('/wordpress/wp-content/themes/understrap/fonts/kumbhsans-regular-webfont.woff2') format('woff2'),
         url('/wordpress/wp-content/themes/understrap/fonts/kumbhsans-regular-webfont.woff') format('woff');
}

.alert {
    background-color: #ec5428;
    color: white;
    height: 40px;
    width: 100%;
    text-align: center;
    border-radius: 0px;
    margin-bottom: 0px;
}

.alert a:link, .alert a:visited {color: white;}

a, a strong, a:visited {
    text-decoration:none;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    transition: all 0.2s linear;
    color:#2a6cb9;
    /*padding-bottom:3px;*/
    }

img a, a img, a img:hover, img a:hover {
        border: none;
   }

hr {
    background-color: #D8C084;
    color: #D8C084;
    margin: 10px 0 20px 0px;
    padding: 0pt;
    width: 100%;
    float: left;
      height: 3px;
      border:0;
    }
    

input {
    font: 17px 'Kumbh Sans', Helvetica, Arial, sans-serif;
    }


.clear {clear:both;}

h2 { font-family: 'kumbh_sansbold', Helvetica; }
h5 { font-family: 'kumbh_sansbold', Helvetica; }

#wrapper-navbar {
    #main-nav {
        ul {
            li {
                &.current_page_item {
                    a {
                        color: #000;
                    }
                }

            }
            
        }

;
    }
    .uwilogo {
        height: 108px;
        
    }
    .secondary-menu {
        background-color: #d2d6d8;
        height: 50px;
    }

}

.home-banner { 
    background-color: #ddd;
    width: 100%;
    border-top: 0px solid #2a6cb9;
}

.home-banner img {width: 100%;}

.find-programme {padding: 20px 100px 20px 100px;}

.sta-news { 
    width: 100%;
    background-image: url(/wordpress/wp-content/themes/understrap/img/news-bg.gif);
    background-color: #aaa;
    background-position: center; 
    background-repeat: no-repeat;
    min-height: 500px;
}

.sta-news-header {
    color: white;
    padding: 50px 0px 30px 0px;
}

.upcoming-events {
    padding: 50px 0px 50px 0px;
}

.button-link {
    background-color: #2a6cb9;
    padding: 15px;
    color: white;
}

.footer-row-1 {
    background-color: #273036;
    padding: 35px 0px 15px 0px;
}

.footer-row-2 {
    background-color: #0e1f2a;
    padding: 15px 0px 15px 0px;
}

.footer-row-1, .footer-row-2 {
    width: 100%;
    color: #9aaeb0;
    line-height: 32px;
    font-size: 14px;
}

.footer-row-1 h5 {text-transform: uppercase; font-size: 16px; color: #fff; letter-spacing: 2px; margin-bottom: 15px;}
.footer-row-1 h5::after {border-bottom: 1px solid #fff; width: 50px; margin-bottom: 20px;}
.footer-row-1 a:link, .footer-row-2 a:link, .footer-row-1 a:visited, .footer-row-2 a:visited, .footer-row-1 a, .footer-row-2 a {color: #fff;}

#footerflags {
    margin:10px 0;
    text-align:center;
  }
  
  #footerflags ul li {
   display:inline; 
  }
  



.contact-detail {line-height: 25px;}

.homepage .embed-responsive, .inside .embed-responsive {
    min-height: 340px !important;
}


  

























/* COMMON STYLES */

.mobileonly {
	display:none;
}

.box-padup {
	padding:50px 0;
}

.box-half, .box-realhalf{
width:50%;
position:relative;
margin:0;
padding:0;
overflow:hidden;	
}

.box-third {
width:33.333333%;
position:relative;
margin:0;
padding:0;
overflow:hidden;
}

.box-twothird {
	width:66.66666%;
position:relative;
margin:0;
padding:0;
overflow:hidden;
}


.box-realhalf{
width:50%;
position:relative;
margin:0;
padding:0;
overflow:hidden;
float:left;
}


.box-realthird {
width:33.333333%;
position:relative;
margin:0;
padding:0;
overflow:hidden;
float:left;
}

.box-realtwothird{
width:66.66666%;
position:relative;
margin:0;
padding:0;
overflow:hidden;
float:left;
}

.box-realhalf img,.box-realtwothird img {
	width:100%;
	height:auto;
}

.box-white {
background:#fff;	
}

.box-offwhite {
	background:#EAECED;
}

.box-offwhite li,.box-red li {
padding-bottom:10px;	
}

.box-lightgrey {
	background:#D1D6D8;
}

.box-grey {
	background:#A2ACB2;
}

.box-darkgrey {
	background:#465A65;
}

.box-darkestergrey {
  background-color:#253037;
}

.box-darkestgrey {
	
	background:#091F2B;
}

.box-navy {
	background:#0F3041;
}

.box-brightred {
	background:#C8242F;
}

.box-red {
	background:$primary;
	background:$primary;
}

.box-red h2, .box-red h4, .box-red a, .box-red p {
 color:#fff!important;	 
}

 .box-red h4 {
	 padding-bottom:0;
 }
 
.box-darkred {
	background:#650101;
}

.white {
color:#fff!important;	
}

.grey {
	color:#A2ACB2!important;
}

.darkgrey {
	color:#465A65!important;
}

.brightblue {
	color:#1987e4!important;
}

.blue {
	color:#2a6cb9;
	color:#1987e4!important;
}

.darkblue {
	color:#0c518b!important;
}

.upper {
text-transform:uppercase;	
}





/* Area Summary, the cards above the footer on the landing pages */


a.speciallink, .areasummary p a, .areasummary li a {
    font-size:14px;
    text-transform:uppercase;
    padding-right: 25px;
    display: initial;
    background: transparent url(../images/arrow_right_red.png) 95% 0px no-repeat;
      -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    transition: all 0.2s linear;
    }
    
    a.speciallink:hover, .areasummary a:hover {
     padding-right: 25px;
      border:none!important;
      background-position:right 0px;
    }
    
    .areasummary li {
    line-height: 1.2;
    padding-bottom: 15px;	
    }
    
    .redblock {
      position: absolute;
    width: calc(60% - 250px);
    height: 610px;
    background: $primary;
    left: 0;
    }
    
    .redblock-long {
      position: absolute;
    width: calc(60% - 250px);
    height: 1075px;
    background: $primary;
    left: 0;
    }




/* Campus Selector & Main Menu */

#auxnav a {
    color:#0E3040!important; 
    padding-left: 20px;
     text-transform:uppercase;
     font-size: 12px;
     letter-spacing:1px;
     padding-bottom:0;
   }
   
   #auxnav a:hover {
   border:none!important; 
   }
   
   #auxnav a.dropdown-item {
     text-transform:normal!important;
   }
   
   #campusselector {
     border-right:1px solid #A2ACB2;
   }
   
   #campusselector .dropdown-menu.show {
     border-radius:0;
   }
   
   #campusselector .dropdown-menu.show .dropdown-item {
   padding-bottom:5px;  
   }
   
   #campusselector .dropdown-menu.show .dropdown-item:hover {
     color:#1987e4!important;
   }



  

   



   #logomainnav {
	border-bottom:3px solid #2a6cb9;
	position:relative;
	    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

#logo {
height:auto;
  z-index:20;
  padding-top: 0px;
}

#logo img {
width:100%;
height:auto;
}

.mainnav {
  	position:relative;
}

.mainnav a{
  padding-bottom:0;
  font-size:19px;
}

.mainnav a:hover {
  border:none!important;
}

.mainnav .dropdown-toggle::after {
  display:none;
}

.mainnav .dropdown-menu {
border-radius:0;
    min-width:250px;
}

.mainnav .dropdown-menu a {
font-size: 15px;
padding: 12px 15px;
color: #16181b !important;
border-bottom: 1px solid #e6e6e6 !important;
}

.mainnav .navbar-expand-lg .dropdown:hover .dropdown-menu {
  display: block;

}

.mainnav .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
    top: 40px;
}

body.offcanvas-active{
	overflow:hidden;
}


.screen-overlay {
  width:0%;
  height: 100%;
  z-index: 30;
  position: fixed;
  top: 0;
  left: 0;
  opacity:0;
  visibility:hidden;
  background-color: rgba(34, 34, 34, 0.6);
  transition:opacity .2s linear, visibility .1s, width 1s ease-in;
   }
.screen-overlay.show {
    transition:opacity .5s ease, width 0s;
    opacity:1;
    width:100%;
    visibility:visible;
}
	
	
@media all and (max-width:992px) {  /* Pick your breakpoint for the off-canvas menu to appear */
	

	.mobile-offcanvas{
		visibility: hidden;
		transform:translateX(-100%);
	    border-radius:0; 
		display:block;
	    position: fixed!important;
	    top: 0; left:0;
	    height: 100%;
	    z-index: 1200;
	    width:85%;
	    overflow-y: /*scroll*/hidden;
	    overflow-x: hidden;
	    transition: visibility .2s ease-in-out, transform .2s ease-in-out;
	}

	.mobile-offcanvas.show{
		visibility: visible;
    	transform: translateX(0);
	}
	
	.mobile-offcanvas.show #logo {
		display:none;
	}
}


#mobilemenubutton {
  margin:25px 10px 20px 20px;
  font-size:15px;
  text-transform:uppercase;
  cursor:pointer;
  float: right;
}

#mainnav .show h3 {
  border-bottom: 2px solid #1987e4;
  background: transparent url(../images/arrow_right_red.png) 15px 7px no-repeat;
  padding-bottom:5px;
  
}

#mainnav .show h3 a{
  padding-left:30px;
  font-size:17px;
}

.mobile-offcanvas .mainnav {
  margin-left:0!important;
  margin-top: 0 !important;
}

.btn-close {
border:2px solid #1987e4;
  border-radius:0;
  background:none;
  text-transform:uppercase;
  font-size:13px;
  color:#1987e4;
  padding-bottom:3px;
}


/* Search */

.hidefuoc { /* Used in conjunction with jQuery to hide the FUOC for the fade-in search panel */
	display:none;
}

#searchcontent {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1500;
    padding: 68px 60px;
    background: rgba(255,255,255,0.95);
    width: 100%;
-webkit-box-shadow: 0px 3px 13px 0px rgba(105,104,105,0.59);
-moz-box-shadow: 0px 3px 13px 0px rgba(105,104,105,0.59);
box-shadow: 0px 3px 13px 0px rgba(105,104,105,0.59);
}

#searchbar, #closesearch{
  cursor:pointer;  
}


#searchbar {

}

.mobile-offcanvas #searchbar {
	padding-top:2px!important;
}

#btn-search {
  margin-left:-37px;
  cursor:pointer;
  
}

 #closesearch {
 position: absolute;
right: 20px;
top: 10px;
 }

.searchbar input[type=text] {
  background:#EAECED;
  padding:12px 0 8px 9px;
  color:#333;
  border:0;
  border:1px solid #ccc;
  font-size: 19px;
}

.searchbar .btn-primary {
  padding: 15px 15px 9px 15px !important;
}

.mobile-offcanvas hr {
    color: #D8C084;
    margin: 5px 0 10px 0px;
}
















/* Faculties Block */

#faculties {
    /*min-height: 60vh;*/
    background-size:cover;
    transition: background 1s linear;
    position:relative;
  }
  
  
  #faculties h2, 
  #faculties p, 
  #faculties a,#facultylisting a,#facultylisting h5 {
   color:#fff!important; 
  }
  
  #faculties h5:after {
      display:none;
  }
  
  #faculties p {
   font-size:19px; 
  }
  
  #aboutacademics {
          padding-left: calc((100% - 1350px) / 2 + 5%);
          padding-right: 10%;
  padding-top: 12%;
  padding-bottom: 12%;	
  }
  
  #facultylisting {
    padding: 40px 30px 20px 30px;
    background: rgba(0,0,0,0.75);
  }
  
  #facultylisting .box-twothird {
  max-width:450px;	
  }
  
  #facultylisting h4 {
  border-bottom: 1px solid rgba(255,255,255,0.5);
  padding-top: 0px;
  padding-bottom: 20px;
  text-transform: none;
  letter-spacing: 0;
  font-size: 23px;
  }
  
  #facultylisting h4:last-child {
    border:none;}
  
  #facultylisting a:hover {
    border:none!important;
    padding-left:10px;
  }
  
  #facultylisting h4:nth-child(1) a:hover + #faculties{
      background: blue;
  }
  
  
  
  #facultybgs {
      height: 100%;
      width: 100%;
      position: absolute;
     left: 0;
    right: 0;
    top: 0;
    bottom: 0;
      z-index: -1;
      display: block;
      pointer-events: none;
  }
  
  .facultybg {
  /*opacity:0;
  visibility:hidden;*/
  height: 100%;
      width: 100%;
  position: absolute;
  top: 0;
  left: 0;
    background-size:cover;
  }
  
  .facultybg.default {
      background: transparent url(/wordpress/wp-content/themes/understrap/img/bg_facultiesblock.jpg) center top no-repeat;
      background-size:cover;
      opacity:1;
      visibility:visible;
  }
  
  .facultybg.engineering {
      background:transparent url(/wordpress/wp-content/themes/understrap/img/bg_facultiesblock_engineering.jpg) left top no-repeat;
      background-size:cover;
  
  }
  
  .facultybg.humanities {
      background:transparent url(/wordpress/wp-content/themes/understrap/img/bg_facultiesblock_humanities.jpg) left top no-repeat;
      background-size:cover;
      opacity:1;
      visibility:visible;
  }
  
  .facultybg.law {
      background:transparent url(/wordpress/wp-content/themes/understrap/img/bg_facultiesblock_law.jpg) left top no-repeat;
      background-size:cover;
  
  }
  
  .facultybg.medical {
      background:transparent url(/wordpress/wp-content/themes/understrap/img/bg_facultiesblock_medical.jpg) left top no-repeat;
      background-size:cover;
  
  }
  
  .facultybg.scitech {
      background:transparent url(/wordpress/wp-content/themes/understrap/img/bg_facultiesblock_scitech.jpg) left top no-repeat;
      background-size:cover;
  
  }
  
  .facultybg.social {
      background:transparent url(/wordpress/wp-content/themes/understrap/img/bg_facultiesblock_social.jpg) left top no-repeat;
      background-size:cover;
  
  }
  
  .facultybg.sport {
      background:transparent url(/wordpress/wp-content/themes/understrap/img/bg_facultiesblock_sport.jpg) left top no-repeat;
      background-size:cover;
  
  }
  
  .facultultylist a {
      padding: 20px 180px 20px 0;
      
  }
  
  .visible {
      visibility:visible;
  }
  
  .novisible {
  visibility:hidden;	
  }



/* Events */

#events .event-date {
	color:#fff;
	text-align:center;
	float:left;
}

#events .event-date p {
	padding-bottom:0;
}

#events .event-date .month {
	font-size:14px;
	color:#fff;
	text-transform:uppercase;
	width: 100%;
float: left;
  line-height:1.1;
}

#events .event-date .day {
	font-size:25px;
	color:#fff;
	text-transform:uppercase;
}

#events .event-data {
	float:left;
}

#events .event-data p{
	font-size:18px;
}

#events small {
text-transform:uppercase;	
}

.homepage .embed-responsive, .inside .embed-responsive {
    min-height: 340px !important;
}

/* By the numbers */


#bythenumbers {
  background:#F6F6F6;
  padding:60px 0 40px 0;
}

#bythenumbers h3 {
  color:$primary;
  font-weight:300;
  font-size:55px;
  margin-bottom:0;
}

#bythenumbers h5 {
  margin-top: 0px;
  margin-bottom: 0px;
  line-height:1.4;
  padding: 15px 0 0px 0;
  font-weight:400;
}

#bythenumbers h5:before {
  position: absolute;
content: '';
width: 50px;
height: 3px;
border-bottom: 3px solid #D1D6D8;
left: 0;
top: 0;
}

#bythenumbers h5:after {
  display:none;
}


/* Major Areas, the cards below Academics and Admissions hero pic */


#majorareas {
  
}

.majorarea {
  height: 405px;
padding: 175px 0;
}

.majorarea h2{
  padding: 0 20%;
}

.majorarea h2 a {
  color:#fff!important;
}


/* News */

p.news-title {
	line-height: 1.2;
}

.homepage #news .news-title a,.inside.landing #news .news-title a {
 font-size:21px; 
}

.homepage #news .news-date,.inside.landing #news .news-date{
  color:#465A65;
  font-size:15px;
}

.homepage #news a.speciallink,.inside #news a.speciallink {
	background: transparent url(../images/arrow_right_white.png) 95% 0px no-repeat;
	color:#fff!important; 
	}

.homepage #news a.speciallink:hover,.inside #news a.speciallink:hover {
	background-position: right 0px;
	}

.homepage #news .card:hover a:hover,.inside #news .card:hover a:hover {
 border:none!important; 
}

p.caption {
text-align:center;
font-size:15px;
color:#666;
margin-bottom:20px;
padding-left:10%;
padding-right:10%;
}


















/* HOME */


#homecontent {
    float: left;
    width: 100%;
    margin: 0 auto;	
     }
     
    #homeslider{ 
       background: transparent url(../wordpress/wp-content/themes/understrap/img/slide_1.jpg) top left repeat-x;	
     
    }
    
    #homeslider .carousel, #homeslider .carousel-inner {
      height:650px;
       overflow:hidden;
    }
    
    #homeslider .carousel-caption {
      text-align: left;
    position: absolute;
    top:200px;
       left:5%;
    }
    
    #homeslider .carousel-control-next, #homeslider .carousel-control-prev {
        display:none;
    }
    
    #homeslider .container{
    /*position: absolute;
    left: 0;
    bottom: 40%;
    margin: auto;
    z-index: 200;*/
    }
    
    #homeslider h1 {
        padding-bottom:20px;
    }
    
    #homeslider p {
        font-size: 19px;
    }
    
    #homeslider .carousel-indicators li {
        width: 50px!important;
        height: 4px!important;
        background-color:#A2ACB2!important;
        opacity:1!important;
    }
    
    #homeslider .carousel-indicators .active {
        background-color:$primary!important;
        background-color:#030303!important;
    }
    
    #homeslider .carousel-indicators {
        justify-content: left!important;
    padding-left: 0!important;
    margin-right: 15%!important;
    margin-left: 5%!important;
    }
    
    
    /* If background for text captioning is required */
    
    #homeslider .carousel  .carousel-caption {
    top: 250px;  
    }
    
    #homeslider .carousel .box-red {
    text-align: left;
    position: absolute;
    top: 200px;
    left: 0%;
    padding-top:30px;
    padding-bottom:20px;
      height:300px;
    }
    
    #homeslider .carousel .box-red h1,#homeslider .carousel .box-red h2, #homeslider .carousel .box-red p, #homeslider .carousel .box-red a {
        color:#fff!important;
    }
    
    #homeslider .carousel .box-red h1 {
        font-weight:300;
        font-size:37px;
            padding-right:20px;
        padding-left:20px;
    }
    
    #homeslider .carousel .box-red p {
        font-size:19px;
        padding-right:20px;
        padding-left:20px;
    }
    
    #homeslider .carousel .btn
    {
        font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: .5rem 1rem;
    }
    
    #homeslider .carousel .btn-secondary {
        background:none!important;
        border:1px solid #fff!important;
        
    }
    













/* INSIDE PAGES */


.inside #mainpic,.inside #pagetitle {
    position:relative;
    
    }
    
    .inside #breadcrumbs {
      
    border-bottom:1px solid #ccc;
    padding-bottom: 15px;
    padding-top: 10px;
    height: 50px;
    font-size: 13px;
    font-family: 'Kumbh Sans',Helvetica,Arial,sans-serif;
    }
    
    .inside #breadcrumbs li{
    font-size: 14px;
    font-family: 'Kumbh Sans',Helvetica,Arial,sans-serif;
    padding-bottom: 0;
    }


/* Landing Pages */

.landing h1 {
    color:#fff; 
   font-size:49px;
     font-weight:300;
   }
   
   .landing #mainpic {
       min-height: 650px; 
       background-size: cover;
   }

.landing h1.entry-title {display: none;}
.homepage h1.entry-title {display: none;}


  /* About Us */

.about.landing #mainpic {
    background: transparent url(/wordpress/wp-content/themes/understrap/img/about.jpg) top center no-repeat;
        background-size: auto;
    background-size: cover;
    width: 100%;
    }
    
.about.landing #ataglance {
       /*background: transparent url(../images/inside/about/bg_ataglance.jpg) left top no-repeat;*/
    }
    
.about.landing #ataglance #pic {
        padding: 0;
        position: absolute;
        left: -65px;
        z-index: -1;
    }
    
.about.landing #withintheregion {
      background-color: $primary;
      background-size:cover;
      padding:20px 0 0px 0;
    }
    
    
.about.landing #withintheregion h3{
      font-weight:300;
      font-size:31px;
      color:#fff;
    }
    
.about.landing #withintheregion h4{
      font-weight:300;
      font-size:23px;
      text-transform:none;
      letter-spacing:0;
       color:#fff;
    }
    
.about.landing #withintheregion p{
       color:#fff!important;
       font-size:19px;
    }
    
.about.landing #withintheregion a{
     text-decoration:underline;
      color:#fff!important;
    }
    


    /* Academics */


.academics.landing #mainpic {
    background: transparent url(/wordpress/wp-content/themes/understrap/img/academics/mainpic.jpg) top center no-repeat;
        background-size: auto;
    background-size: cover;
    }
    
    .academics.landing #undergrad {
       background: transparent url(/wordpress/wp-content/themes/understrap/img/academics/bg_undergrad.jpg) top center no-repeat;
       background-size:cover;
    }
    
    .academics.landing #postgrad {
         background: transparent url(/wordpress/wp-content/themes/understrap/img/academics/bg_postgrad.jpg) top center no-repeat;
          background-size:cover;
    }
    
    .academics.landing #research {
         background: transparent url(/wordpress/wp-content/themes/understrap/img/academics/bg_research.jpg) top center no-repeat;
          background-size:cover;
    }
    
    
    
    
    /* Admissions */
    
    .admissions.landing #mainpic {
    background: transparent url(/wordpress/wp-content/themes/understrap/img/admissions/mainpic.jpg) top center no-repeat;
        background-size: auto;
    background-size: cover;
    }
    
    .admissions.landing #undergrad {
       background: transparent url(/wordpress/wp-content/themes/understrap/img/admissions/bg_undergrad.jpg) top center no-repeat;
       background-size:cover;
    }
    
    .admissions.landing #postgrad {
         background: transparent url(/wordpress/wp-content/themes/understrap/img/admissions/bg_postgrad.jpg) top center no-repeat;
          background-size:cover;
    }
    
    .admissions.landing #financial {
         background: transparent url(/wordpress/wp-content/themes/understrap/img/admissions/bg_aid.jpg) top center no-repeat;
          background-size:cover;
    }
    
    .admissions.landing #whyuwi h2 {
      font-weight:300;
      font-size:39px;
      letter-spacing:-1px;
    }
    
    .admissions.landing #whyuwi #topreasons h5 {
      color:#fff;
      padding-bottom: 20px;
    }
    
    .admissions.landing #whyuwi #topreasons h5:after {
      contnet:'';
      width:50px;
      position:absolute;
      bottom:0;
      border:3px solid #fff;
     left:48%;
    }
    
    .admissions.landing #whyuwi #topreasons h2 {
      color:#fff;
    }
    
    .admissions.landing #whyuwi #topreasons p {
      color:#fff;
      padding-bottom: 40px;
    }
    
    .admissions.landing #whyuwi #topreasons .carousel-indicators {
      padding-bottom: 20px;
    }
    
    .admissions.content #hearpelicans h2 {
    font-weight:300;	
    }
    
    
    
    
    
    
    /* Campus Life */
    
    
    .campuslife.landing #mainpic {
    background: transparent url(/wordpress/wp-content/themes/understrap/img/campuslife/mainpic.jpg) top center no-repeat;
        background-size: auto;
    background-size: cover;
    }
    
    .campuslife.landing #vibrantcommunity p a{
     padding-left:35px;
    }
    
    .campuslife.landing #vibrantcommunity p {
     padding-bottom:25px;
    }
    
    .campuslife.landing #vibrantcommunity #clubs {
       background: transparent url(/wordpress/wp-content/themes/understrap/img/campuslife/bg_clubs.png) left 8px no-repeat;
    }
    
    .campuslife.landing #vibrantcommunity #cafeteria {
       background: transparent url(/wordpress/wp-content/themes/understrap/img/campuslife/bg_cafeteria.png) left 8px no-repeat;
    }
    
    
    .campuslife.landing #vibrantcommunity #sports {
       background: transparent url(/wordpress/wp-content/themes/understrap/img/campuslife/bg_sports.png) left 8px no-repeat;
    }
    
    
    .campuslife.landing #vibrantcommunity #shopping {
       background: transparent url(/wordpress/wp-content/themes/understrap/img/campuslife/bg_shopping.png) left 8px no-repeat;
    }
    
    
    .campuslife.landing #vibrantcommunity #culture {
       background: transparent url(/wordpress/wp-content/themes/understrap/img/campuslife/bg_culture.png) left 8px no-repeat;
    }
    
    
    .campuslife.landing #vibrantcommunity #offcampus {
       background: transparent url(/wordpress/wp-content/themes/understrap/img/campuslife/bg_offcampus.png) left 8px no-repeat;
    }
    
    
    .campuslife.landing #vibrantcommunity #health {
       background: transparent url(/wordpress/wp-content/themes/understrap/img/campuslife/bg_health.png) left 8px no-repeat;
    }
    
    
    .campuslife.landing #vibrantcommunity #jobs {
       background: transparent url(/wordpress/wp-content/themes/understrap/img/campuslife/bg_jobs.png) left 8px no-repeat;
    }
    
    .campuslife.landing #accommodations {
       background: $primary url(/wordpress/wp-content/themes/understrap/img/campuslife/bg_accommodations.jpg) -100px top no-repeat;
    }
    
    
    
    
    
    
    
    
    /* Research */
    
    
    .research.landing #mainpic {
    background: transparent url(/wordpress/wp-content/themes/understrap/img/research/mainpic.jpg) top center no-repeat;
        background-size: auto;
    background-size: cover;
    }
 


























/*******************************************************************/

/* HACK FOR WEBKIT */
@media screen and (-webkit-min-device-pixel-ratio:0) {
	



}


/*******************************************************************/
/* HACK FOR IE 10 */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {



}




/*******************************************************************/



@media only screen and (min-width: 320px) and (max-width:479px) {

     
    body {
      overflow-x:hidden;
    }
      
      #auxnav {
       display:none; 
      }
      
    .box-third, .box-half, .box-twothird {
        width:100%!important;
    }
    
    .mobileonly {
        display:block;
    }
    
    .mobilenone {
    display:none;	
    }  
      .mainnav .nav-item.mobileonly a {
      font-size:15px;
      }
    
    #searchcontent {
        padding: 60px 20px;
    }
    
    .searchbar {
        margin-left: 0 !important;
    }
    
    .searchbar input[type="text"],.searchbar input[type="button"]{
                     width:100%;
                     float:left;
        max-width: 100%!important;
        padding: 10px;
    }
    
    
    .searchbar input[type="button"] {
        padding: 12px 10px;
    }
    
    #findcourse input[type=text],#findcourse button {
     width:100%; 
    }
    
      
    #degreelevels #extralinks {
    border:0!important;	
    }
    
    
    #degreelevels h5, #extralinks h5 {
     padding-left:20px;
      margin-bottom:10px!important;
    }
    
     #extralinks h5 {
         padding-left:0!important;
     }
     
    .redblock {
        position: absolute;
        width: 250px;
        height: 610px;
        background: #BA202B;
        left: 0;
    }
    
    .redblock-long {
        position: absolute;
        width: 250px;
        height: 1135px;
        background: #BA202B;
        left: 0;
    }
    
    #news, #events {
    padding-bottom:0!important;	
    }
      
    #faculties {
      display:block!important;
    }
      
    #faculties #aboutacademics, #faculties #facultylisting {
      padding:20px!important;
    }
    
    #bythenumbers {
        padding: 20px 0;
    }
      
    #bythenumbers .col-lg-4 {
     margin-bottom:10px!important; 
     padding-top: 10px !important;
    }
      
    .areasummary .col-lg-6  {
      padding-left:10px!important;
      padding-top:10px!important;
    }
     
     
      
     
      
    /* Home */
      
    #homeslider .carousel, #homeslider .carousel-inner {
        height: 100%!important;
        overflow: hidden;
    }
    
    #homeslider h1 {
        font-size:21px;
    }
    
      #homeslider .carousel  .carousel-caption {
    top: 20px;  
    }
      
      #homeslider .carousel .box-red {
    text-align: left;
    position: absolute;
    top:20px;
    left: 0%;
        width:55%;
    padding-top:20px;
    padding-bottom:20px;
    }
      
      #homeslider .carousel .box-red h1 {
        font-size:21px;
        padding-left:0;
      }
      
    #homeslider p {
        display:none;
    }
      
     .homepage #foryou {
     padding-top:20px!important;
    }
     
     .homepage #uwitv {
        padding-bottom:0!important; 
     }
      
      
    
    /* Inside Pages */
    
    .inside.landing #mainpic {
        padding-bottom: 0 !important;
        min-height: 80vh !important;
    }
    
    .inside.landing h1 {
        color: #fff;
        font-size: 37px;
    }
    
    .inside #sidebar {
        border-right: none;
    }
    
    .inside .sidenav { 
        border-top:2px solid #000;
        padding-top:10px;
    }
    
    
    /* About Us */
      
     .about.landing #ataglance {
       padding-top:0!important; 
       padding-bottom:0!important;
      }
      
    .about.landing #ataglance #pic {
    display:none;
    }  
      
      
    .about.landing #ataglance .offset-md-3 {
     padding-top:0!important; 
    }
      
    .about.landing #withintheregion h3 {
        font-size: 27px;
    }  
      
    .about.landing #withintheregion h4 {
        font-size: 21px;
    }
       
    .about.landing #withintheregion p {
        font-size: 17px;
    }  
     
     
     
    /* Campus Life */
    
    .campuslife.landing #accommodations {
        background-image: none;
    }
    
    .campuslife.landing #vibrantcommunity p {
        padding-bottom: 15px;
    }
    
    .campuslife.landing #vibrantcommunity p a {
        padding-left: 0px;
    }
    
    .campuslife.landing #vibrantcommunity .areasummary .col-10 {
        background:none!important;
      padding-left:0;
    }
    
    
    
    /* Admissions */
    
    .admissions.content #hearpelicans h2 {
        font-size: 25px;
        padding-left: 10px!important;
        padding-right: 20px!important;
    }
    
    .admissions.content #hearpelicans p {
          padding-left: 10px!important;
    }
    
    
    
    
    }
    
    
    
    
    /*******************************************************************/
    
    
    
    @media only screen and (min-width: 480px) and (max-width:767px) {
       
    body {
      overflow-x:hidden;
    }
      
      #auxnav {
       display:none; 
      }
      
    .box-third, .box-half, .box-twothird {
        width:100%!important;
    }
    
    .mobileonly {
        display:block;
    }
    
    .mobilenone {
    display:none;	
    }
    
    #mobilemenubutton {
        margin: 40px 10px 20px 20px;
    }
    
    #searchcontent {
        padding: 60px 20px;
    }
    
    .searchbar {
        margin-left: 0 !important;
    }
    
    .searchbar input[type="text"],.searchbar input[type="button"]{
                     width:100%;
                     float:left;
        max-width: 100%!important;
    } 
      
    .homepage #degreelevels #extralinks {
    border:0;	
    }
    
    #findcourse input[type=text],#findcourse button {
     width:100%; 
    }
      
    #degreelevels #extralinks {
    border:0!important;	
    }
    
    #degreelevels h5, #extralinks h5 {
     padding-left:20px;
      margin-bottom:10px!important;
    }
      
    .redblock {
        position: absolute;
        width: 350px;
        height: 510px;
        background: #BA202B;
        left: 0;
    }  
    
    .redblock-long {
        position: absolute;
        width: 250px;
        height: 885px;
        background: #BA202B;
        left: 0;
    }
      
      #news, #events {
        padding-bottom:20px!important;
      }
      
    #faculties {
      display:block!important;
    }
      
    #faculties #aboutacademics, #faculties #facultylisting {
      padding:20px!important;
    }
      
     
    #bythenumbers {
        padding: 20px 0;
    }
      
    #bythenumbers .col-lg-4 {
     margin-bottom:10px!important; 
    }
      
    .areasummary .col-lg-6  {
      padding-left:10px!important;
      padding-top:10px!important;
    }
     
     
      
    /* Home */
      
    #homeslider .carousel, #homeslider .carousel-inner {
        height: 100%!important;
        overflow: hidden;
    }
     
    #homeslider h1 {
        font-size:27px;
    }
    
    #homeslider .carousel  .carousel-caption {
    top: 100px;  
    }
      
      #homeslider .carousel .box-red {
    text-align: left;
    position: absolute;
    top:50px;
        height:200px;
    left: 0%;
    padding-top:20px;
    padding-bottom:20px;
        width:55%;
    }
      
      #homeslider .carousel .box-red h1 {
        font-size:23px;
        padding-left:0;
        padding-left:20px;
      }
      
    #homeslider p {
    font-size:17px!important;
    }
     
     .homepage #foryou {
     padding-top:20px!important;
    }
     
     .homepage #uwitv {
        padding-bottom:0!important; 
     }
       
    
    /* Inside Pages */
    
    .inside.landing #mainpic {
        padding-bottom: 0 !important;
        min-height: 70vh !important;
    }
    
    .inside.landing h1 {
        color: #fff;
        font-size: 37px;
    }
    
    .inside #sidebar {
        border-right: none;
    }
    
    .inside .sidenav { 
        border-top:2px solid #000;
        padding-top:10px;
    }
    
    
     
    
    /* About Us */
      
     .about.landing #ataglance {
       padding-top:0!important; 
       padding-bottom:0!important;
      }
      
    .about.landing #ataglance #pic {
    display:none;
    }  
      
      
    .about.landing #ataglance .offset-md-3 {
     padding-top:0!important; 
    }
      
    .about.landing #withintheregion h3 {
        font-size: 27px;
    }  
      
    .about.landing #withintheregion h4 {
        font-size: 21px;
    }
       
    .about.landing #withintheregion p {
        font-size: 17px;
    }  
     
     
     
    /* Campus Life */
    
    .campuslife.landing #accommodations {
        background-image: none;
    }
    
    .campuslife.landing #vibrantcommunity p {
        padding-bottom: 15px;
    }
    
    .campuslife.landing #vibrantcommunity p a {
        padding-left: 10px;
    }
    
    .campuslife.landing #vibrantcommunity .areasummary .col-10 {
        background:none!important;
      padding-left:0;
    }
    
    
    
    /* Admissions */
    
    .admissions.content #hearpelicans h2 {
        font-size: 25px;
        padding-left: 10px!important;
        padding-right: 20px!important;
    }
    
    .admissions.content #hearpelicans p {
          padding-left: 10px!important;
    }
    
    
    
    
    
      
      
    
    
    }
    
    
    /*******************************************************************/
    
    
    
    @media only screen and (min-width: 768px) and (max-width:959px) {
      
     
      #auxnav {
       display:none; 
      }
      
    .box-third, .box-half, .box-twothird {
        width:100%!important;
    }
    
    .mobileonly {
        display:block;
    }
      
    .mobilenone {
    display:none;	
    }
    
    #mobilemenubutton {
        margin: 40px 10px 20px 20px;
    }
      
    #degreelevels #extralinks {
    border:0!important;	
      margin:0!important;
    }
    
    #degreelevels h5 {
        margin-bottom: 0;
        padding-left: 15px;
    }
      
      #degreelevels .col-md-6 {
       margin:0!important; 
      }
    
    .redblock {
        position: absolute;
        width: 450px;
        height: 510px;
        background: #BA202B;
        left: 0;
    }  
     
    .redblock-long {
        position: absolute;
        width: 450px;
        height: 895px;
        background: #BA202B;
        left: 0;
    }
     
      #news, #events {
        padding-bottom:20px!important;
      }
      
      #events .col-xl-6 {
       padding-left:0!important;
        margin-bottom:10px!important;
      }
      
    #faculties {
      display:block!important;
    }
      
    #faculties #aboutacademics, #faculties #facultylisting {
      padding:20px!important;
    }
       
     
    #bythenumbers {
        padding: 20px 0;
    }
      
    #bythenumbers .col-lg-4 {
     margin-bottom:10px!important; 
    }
      
    .areasummary .col-lg-6  {
      padding-left:0px!important;
      padding-top:10px!important;
    } 
     
    
    
    
      
    /* Home */
      
    #homeslider .carousel, #homeslider .carousel-inner {
        height: 100%!important;
        overflow: hidden;
    }
     
    #homeslider .carousel .carousel-caption {
          max-width:75%!important;
    top: 100px;  
    }
      
    #homeslider .carousel .box-red {
    text-align: left;
    position: absolute;
    top:100px;
      left: 0%;
      height:200px;
    padding-top:20px;
    padding-bottom:20px;
      width:55%;
    }
      
      #homeslider .carousel .box-red h1 {
        font-size:23px;
        padding-left:0;
        padding-left:20px;
      }
        
      #homeslider .carousel p {
       font-size:17px; 
      }
       
     .homepage #foryou {
     padding-top:20px!important;
    }
     
     .homepage #uwitv {
        padding-bottom:0!important; 
     }
       
    
    /* Inside Pages */
    
    .inside.landing #mainpic {
        padding-bottom: 0 !important;
        min-height: 70vh !important;
    }
    
    
    /* About Us */
      
     .about.landing #ataglance {
       padding-top:0!important; 
      }
       
    .about.landing #ataglance .row {
      justify-content: baseline!important; 
    }
      
    .about.landing #ataglance #pic {
    display:none;
    }  
       
    .about.landing #ataglance .offset-md-3 {
     padding-top:0!important;
      margin-left:10px!important;
    }
      
    .about.landing #withintheregion h3 {
        font-size: 25px;
    }  
      
    .about.landing #withintheregion h4 {
        font-size: 21px;
    }
    
      
    
     
    /* Campus Life */
    
    .campuslife.landing #accommodations {
        background-position: -350px top;
    }
      
    
    
    
    
    /* Admissions */
    
    .admissions.content #hearpelicans h2 {
        font-size: 25px;
        padding-left: 10px!important;
        padding-right: 20px!important;
    }
    
    .admissions.content #hearpelicans p {
          padding-left: 10px!important;
    }
    
    
    }
    
    
    
    /*******************************************************************/
    
    
    
    @media only screen and (min-width: 960px) and (max-width:1160px) {
      
     
    .box-third, .box-half, .box-twothird {
        width:100%!important;
    }
      
    .box-third.majorarea {
        width: 33.333333%!important;
      }
      
     
    #mobilemenubutton {
        margin: 40px 10px 20px 20px;
    }
    
    #logomainnav .btn-close {
        display:none;
    }
    
    .mobileonly {
        display:block;
    }
    
    .mobilenone {
    display:none;	
    }
    
    
    
    #degreelevels #extralinks {
    border:0!important;	
      margin:0!important;
    }
    
    #degreelevels h5 {
        margin-bottom: 0;
        padding-left: 15px;
    }
      
      #degreelevels .col-md-6 {
       margin:0!important; 
      }
    
    .redblock {
        position: absolute;
        width: 450px;
        height: 510px;
        background: #BA202B;
        left: 0;
    }  
      
      #news, #events {
        padding-bottom:20px!important;
      }
      
      #events .col-xl-6 {
       padding-left:0!important;
        margin-bottom:10px!important;
      }
     
    #faculties #aboutacademics, #faculties #facultylisting {
      padding:20px!important;
    }
      
    #faculties #aboutacademics, #faculties #facultylisting {
       width:50%!important;
    display:block;
      float:left;
      }
    
    
    
      
    /* Home */
      
    #homeslider .carousel, #homeslider .carousel-inner {
        height: 100%!important;
        overflow: hidden;
    }
    
        #homeslider .carousel .carousel-caption {
          max-width:45%!important;
         top:150px;
        }
      
      #homeslider .carousel .box-red {
    text-align: left;
    position: absolute;
    top:100px!important;
    left: 0%;
    padding-top:20px;
    padding-bottom:20px;
        
    }
      
      #homeslider .carousel .box-red h1 {
        padding-left:0;
        padding-left:20px;
      }
        
      #homeslider .carousel p {
       font-size:17px; 
      }  
     .homepage #foryou {
     padding-top:20px!important;
    }
     
     .homepage #uwitv {
        padding-bottom:0!important; 
     }
      
    
    .about.landing #ataglance #pic {
        left: -400px;
    
    }
    
    
     
    /* Campus Life */
    
    .campuslife.landing #accommodations {
        background-position: -280px top;
    }
      
    
      
      
    
    
    }
    
    
    
    
    
    /*******************************************************************/
    @media only screen and (min-width: 991px) and (max-width:1160px) {
      
     /*#mobilemenubutton {
        display:block!important; 
     }*/
      
       
    #auxnav, .searchbutton.mobilenone {
       display:block!important;
    
      }
         
    #auxnav .navbar-nav {
     margin-top:0!important; 
    } 
        
    #mobilelogo {
        display:none;
      }  
      
    .mainnav .mobileonly {
      display:none;
    }
    
      #logomainnav #logo {
        margin-right:10px!important;
      }
      
      .mainnav a {
        padding-bottom: 0;
        font-size: 18px;
    }
      
      .mainnav .nav-link {
        padding-right: .3rem!important;
        padding-left: .3rem!important;
    }  
      
      
     
    }
    
    
    
    
    /*******************************************************************/
    @media only screen and (min-width: 1161px) {
    
        
      .about.landing #ataglance #pic {
        left: -250px;
      }
      
       
      
    }
    
    
    
    /*******************************************************************/
    @media only screen and (min-width: 1780px) {
    
        
      .about.landing #ataglance #pic {
        left: 0px;
      }
      
       
      
    }
    
    



  
 






